<template>
  <div class="input-finder" tabindex="0">
    <input
      ref="input"
      class="input-finder__search"
      type="text"
      :placeholder="placeholder"
      v-model="inputValue"
    />

    <Button
      theme="icon"
      @click="clearFilter()"
      class="input-finder__remove-wrapper"
    >
      <DeleteText />
    </Button>

    <ul v-show="isOpen" class="input-finder__list-body">
      <li
        class="input-finder__list-item"
        v-for="(hintItem, ind) in hintList"
        :key="ind"
        @click="selectResult(hintItem)"
      >
        {{ hintItem }}
      </li>
    </ul>
  </div>
</template>

<script>
import Button from "@/components/Button.vue";
import DeleteText from "@/assets/img/delete-text.svg?inline";

export default {
  name: "InputFinder",
  components: {
    Button,
    DeleteText,
  },
  props: {
    placeholder: {
      type: String,
      default: "Unset",
    },
    isInstant: {
      type: Boolean,
      default: false,
    },
    findList: {
      type: Array,
      default: () => [],
    },
    filterTypes: {
      type: Array,
      default: () => [],
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isOpen: false,
      inputValue: "",
    };
  },
  mounted() {
    this.$nextTick(() => {
      if (this.autofocus) {
        this.$refs.input.focus();
      }
    });
  },
  computed: {
    hintList() {
      const filterName = this.inputValue.trim().toLowerCase();
      let findResult = [];
      this.findList.forEach((project) => {
        return this.filterTypes.forEach((filterType) => {
          let fields = project[filterType];
          if (!fields || fields.length === 0) {
            return false;
          }
          if (typeof fields === "string") {
            if (fields.toLowerCase().includes(filterName)) {
              findResult.push(fields);
            }
          } else if (typeof fields === "object") {
            if (!Array.isArray(fields)) {
              fields = [fields];
            }
            fields.forEach((fieldItem) => {
              Object.keys(fieldItem).forEach((key) => {
                if (
                  key !== "id" &&
                  fieldItem[key].toLowerCase().includes(filterName)
                ) {
                  findResult.push(fieldItem[key]);
                }
              });
            });
          } else {
            console.warn("необработанный тип данных при поиске");
          }
        });
      });
      return findResult;
    },
  },
  watch: {
    inputValue(newVal, lastVal) {
      if (newVal.length < 3 && newVal < lastVal) {
        this.$emit("changeValue", "");
        return;
      }
      if (this.isInstant) {
        this.setValue();
        return;
      }
      if (this.inputValue.length > 2) {
        this.isOpen = true;
      } else {
        this.isOpen = false;
      }
    },
  },
  methods: {
    setValue() {
      if (this.inputValue.length < 3) {
        return;
      }
      this.$emit("changeValue", this.inputValue);
    },
    selectResult(result) {
      this.inputValue = result;
      setTimeout(() => {
        this.isOpen = false;
        this.setValue();
      }, 250);
    },
    clearFilter() {
      this.inputValue = "";
    },
  },
};
</script>

<style scoped>
.input-finder {
  position: relative;
  max-width: 200px;
  max-height: 50vh;
  outline: none;
  background-color: #f3f3f3;
}

.input-finder__search {
  border-radius: 2px;
  line-height: 1.6;
  padding-left: 7px;
  width: 100%;
}

.input-finder__search::placeholder {
  color: #a3b1b4;
}

.input-finder__remove-wrapper {
  position: absolute;
  top: 4px;
  right: 4px;
  width: 20px;
  height: 20px;
  color: #98a3a5;
}

.input-finder__list-body {
  background-color: #f3f3f3;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 9;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  overflow: auto;
  box-shadow: 4px 8px 8px -4px rgba(34, 60, 80, 0.2);
  border-radius: 0px 0px 6px 6px;
}

.input-finder__list-body::-webkit-scrollbar {
  width: 4px;
}

.input-finder__list-body::-webkit-scrollbar-thumb {
  background-color: #c1d2dd;
  border-radius: 10em;
}

.input-finder__list-item {
  padding: 5px 10px;
  cursor: pointer;
}
</style>
