export default {
  watch: {
    "$route.query.page": {
      async handler(pageNumberCurrent, pageNumberLost) {
        if (pageNumberCurrent === pageNumberLost) {
          return;
        }
        await this.setPageNumber(pageNumberCurrent);
      },
      immediate: true,
    },
  },
};
