<template>
  <div v-if="isExist" ref="pagination" class="pagination">
    <div class="pagination__pages-list">
      <Button
        v-for="page of pages"
        :key="page"
        class="pagination__page"
        :class="{ 'pagination__page--current': page === currentPage }"
        @click="clickPage(page)"
      >
        {{ page }}
      </Button>
    </div>
  </div>
</template>

<script>
export default {
  name: "Paginations",
  props: ["data"],
  computed: {
    isExist() {
      return this.data && this.pages > 1;
    },
    dataParams() {
      if (this.data?.next || this.data?.previous) {
        const url = new URL(this.data.next || this.data.previous);
        const limit = url.searchParams.get("limit");
        const offset = url.searchParams.get("offset");
        return { limit, offset, source: this.data.next ? "next" : "previous" };
      }
      return null;
    },
    currentPage() {
      let pageNumber = 1;
      if (this.dataParams) {
        pageNumber =
          this.dataParams?.source === "next"
            ? this.dataParams.offset / this.dataParams.limit
            : this.dataParams.offset / this.dataParams.limit + 2;
      }
      return pageNumber;
    },
    pages() {
      return this.dataParams
        ? Math.ceil(this.data.count / this.dataParams.limit)
        : 1;
    },
  },
  methods: {
    clickPage(pageNumber) {
      if (pageNumber !== parseInt(this.$route.query.page)) {
        this.$router.push({ query: { page: pageNumber } });
      }
      this.$emit("clickPage", pageNumber);
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/css/variables.scss";
.pagination {
  display: flex;
  flex-wrap: wrap;
  margin: 40px 0;
  gap: 8px;
}

.pagination__pages-list {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.pagination {
  button {
    user-select: none;
    height: 40px;
    min-width: 40px;
    padding: 0;
    font-weight: 600;
    color: $erp-dark-color;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    border: 1px solid $erp-dark-color;
    &.pagination__nav {
      white-space: nowrap;
      font-size: 16px;
      width: auto;
      border: none;
    }
  }

  .pagination__page--current {
    background-color: $erp-dark-color;
    color: #ffffff;
  }
}
</style>
